const timeouts = require('../shared/request-timeout');
const $ = require('jquery');


//
// Helpers
//

const typeWords = (destination, text, speed, end) => {
  $('.Cursor').css('visibility', 'hidden');
  $(destination).after('<span class="Cursor is-visible">\n|</span>');

  const deferred = new $.Deferred();
  let endDelay;

  /* eslint-disable no-param-reassign */
  if (typeof speed === 'object') {
    // new syntax! speed is actually an options object
    end = speed.end || text;
    endDelay = speed.endDelay || 200; // 0 is falsy :(
    speed = speed.speed || 30;
  } else {
    end = end || text;
    speed = speed || 30;
    endDelay = 200;
  }
  /* eslint-enable no-param-reassign */

  const writeLetter = () => {
    if (text.length === 0) {
      destination.html(end);
      timeouts.requestTimeout(() => {
        deferred.resolve();
      }, endDelay);
    } else {
      if (text.length === 1) {
        // hack. remove the newline space we added here earlier to prevent wrapping
        $('.Cursor').text('|');
      }

      destination.append(text.charAt(0));
      text = text.slice(1); // eslint-disable-line no-param-reassign

      timeouts.requestTimeout(writeLetter, (Math.random() * 20) + speed);
    }
  };

  writeLetter();

  return deferred.promise();
};

const $ccClara = document.querySelector('.CcClara.u-fadeIn');
const $explanation = document.querySelector('.Explanation.u-fadeIn');
const $points = document.querySelectorAll('.Point.u-fadeIn');
const $testimonials = document.querySelector('.Testimonials > .u-fadeIn');
const $cardRowTop = document.querySelector('.InfoCardRow-top');
const $cardRowBottom = document.querySelector('.InfoCardRow-bottom');
const $complexNeedsBuffer = document.querySelector('.ComplexNeeds-headingBuffer.u-fadeIn');
const infoCards = document.querySelectorAll('.InfoCard');

const playAnimation = (increment) => {
  const fadeIn = (el, index) => {
    el.style['transition-delay'] = `${100 * index}ms`; // eslint-disable-line no-param-reassign
    el.classList.add('is-visible');
  };

  switch (increment) {
    case 'CcClara': {
      fadeIn($ccClara);
      break;
    }

    case 'WhatYouGet': {
      fadeIn($explanation);
      $points.forEach(fadeIn);
      break;
    }

    case 'Testimonials': {
      fadeIn($testimonials);
      break;
    }

    case 'ComplexNeeds': {
      $cardRowTop.classList.add('u-moveLeft');
      $cardRowBottom.classList.add('u-moveRight');

      fadeIn($complexNeedsBuffer);

      infoCards.forEach(fadeIn);
      break;
    }

    case 'SignupForm': {
      fadeIn(document.querySelector('.SignupForm > .u-fadeIn'));
      break;
    }
  }
};

module.exports = {
  playAnimation: playAnimation,
  typeWords: typeWords,
};
