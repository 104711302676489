Node.prototype.on = window.on = function(name, fn) {
  this.addEventListener(name, fn);
};

NodeList.prototype.__proto__ = Array.prototype; // jshint ignore:line

const $emails = document.querySelectorAll('.Email');
const $firstEmailCc = document.querySelector('.Email--1 .Email-headingText.is-hidden');
const $CcClaraSection = document.querySelector('.CcClara');
const $animationSection = document.querySelector('#email-animation');
const $animationWrapper = document.querySelectorAll('.EmailAnimation-wrapper')[0];
const $inviteBoxWrapper = document.querySelector('.InviteBox-wrapper');
const $inviteBoxEvent = document.querySelectorAll('.InviteBox')[0];
const $inviteBoxEmail = document.querySelectorAll('.InviteBox')[1];

// compensate for window height for space underneath animation
// if (window.innerHeight < 1000) {
//   $animationSection.style.height = `${ $animationSection.offsetHeight }px`;
// }

const FIRST_MESSAGE = -400;
const FIXED_OFFSET = 1000;
const FIXED_TOP_OFFSET = 10;
const INVITATIONS_OFFSET = FIXED_OFFSET - 100;

const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

const effectsSpec = [
  {
    start: 10,
    end: FIXED_OFFSET,
    active() {
      $animationWrapper.style.position = 'fixed';
      $animationWrapper.style.top = `-${FIXED_TOP_OFFSET}px`;
    },

    inactive() {
      $animationWrapper.style.position = null;
      $animationWrapper.style.top = null;

      // TODO(stephen): hack! :<
      // this will remove the styles on animationWrapper, including the ones set by the
      // next effect. Should mark that effect deactivated so it'll get re-written.

      effectsSpec[1]._activated = false;
    },
  },
  {
    start: FIXED_OFFSET,
    active() {
      $animationWrapper.style.position = 'absolute';
      $animationWrapper.style.top = (FIXED_OFFSET - FIXED_TOP_OFFSET) + 'px';
    },
  },
  {
    start: FIRST_MESSAGE,
    active() {
      $firstEmailCc.classList.remove('is-hidden');
      $firstEmailCc.classList.add('is-animating');
    },

    inactive() {
      $firstEmailCc.classList.add('is-hidden');
      $firstEmailCc.classList.remove('is-animating');
    },
  },
  {
    start: FIRST_MESSAGE + 200,
    active() {
      $CcClaraSection.classList.add('is-hidden');
    },

    inactive() {
      $CcClaraSection.classList.remove('is-hidden');
    },
  },
  {
    start: INVITATIONS_OFFSET,
    active() {
      $inviteBoxEvent.classList.add('is-visible');
      $inviteBoxEmail.classList.add('is-visible');
    },

    inactive() {
      $inviteBoxEvent.classList.remove('is-visible');
      $inviteBoxEmail.classList.remove('is-visible');
    },
  },
];

if (window.Modernizr.touch) {
  let totalOffset = -20;
  let mobileOffset = 0;

  $emails.forEach(($email, i) => {
    mobileOffset = 30 + $email.querySelector(
      '.Email-explanationText'
    ).getBoundingClientRect().height;

    $email.style.transform = `translate3d(0, ${totalOffset}px, 0) scale(0.9 + 1)`; // eslint-disable-line no-param-reassign, max-len
    $email.style['-webkit-transform'] = `translate3d(0, ${totalOffset}px, 0) scale(${0.82 + 0.02 * i})`; // eslint-disable-line no-param-reassign, max-len

    totalOffset += mobileOffset;
  });

  const inviteBoxWrapperOffset = (60 * $emails.length) +
    $emails[$emails.length - 1].getBoundingClientRect().height - 150;

  $inviteBoxWrapper.style.transform = `translate3d(0, ${inviteBoxWrapperOffset}px, 0)`;
  $inviteBoxWrapper.style['-webkit-transform'] = `translate3d(0, ${inviteBoxWrapperOffset}px, 0)`;

  $inviteBoxEvent.style.transform = 'rotateZ(-4deg) translateY(0) translateX(1rem)';
  $inviteBoxEvent.style['-webkit-transform'] = 'rotateZ(-4deg) translateY(0) translateX(1rem)';

  $inviteBoxEmail.style.transform = 'rotateZ(4deg) translateY(0) translateX(-1rem)';
  $inviteBoxEmail.style['-webkit-transform'] = 'rotateZ(4deg) translateY(0) translateX(-1rem)';

  if (window.innerHeight > 700) {
    $inviteBoxEvent.style.transform = 'translateY(0) translateX(1rem)';
    $inviteBoxEvent.style['-webkit-transform'] = 'translateY(0)';

    $inviteBoxEmail.style.transform = 'translateY(0) translateX(-1rem)';
    $inviteBoxEmail.style['-webkit-transform'] = 'translateY(0)';
  }

  $animationSection.style.height = '1000px';
}

const updateEffect = (updateScrollY) => {

  const landingHeight = document.querySelector('.AboveTheFold').offsetHeight;
  const logoHeight = document.querySelector('.LogoSection').offsetHeight;
  const ccClaraHeight = document.querySelector('.CcClara').offsetHeight;

  const lastScrollY = updateScrollY - landingHeight - logoHeight - ccClaraHeight;

  // use breakpoints to animate on mobile devices
  if (window.Modernizr.touch) {
    return;
  }

  effectsSpec.forEach((effect) => {
    if (lastScrollY >= effect.start &&
      (!effect.end || lastScrollY < effect.end)) {
      if (effect.active && !effect._activated) {
        effect.active();
        effect._activated = true; // eslint-disable-line no-param-reassign, max-len

      }
    } else {
      if (effect.inactive && effect._activated) {
        effect.inactive();
        effect._activated = false; // eslint-disable-line no-param-reassign, max-len
      }
    }
  });

  $emails.forEach(($email, i) => {

    // trigger location
    // const bottom = -730 + (i * 50);
    const bottom = -830 + (i * 50);

    // leading space above element
    const base = 50 * i; // original was 40, increased it

    // translation distance (up/down movement)
    const translation = 100 + 500 * i;

    // movement speed? this needs to be understood better.
    const factor = 1 - 0.1 - (i * 0.08);

    // translate things
    const calcTranslate = -1 * (clamp(
      (lastScrollY - bottom - 400) / factor, base, translation
    ) - translation - base);

    const scale = clamp(1 - (0.05 * $emails.length) + (0.01 * (i)), 0, 1);

    // apply transforms
    $email.style.transform = `translate3d(0, ${calcTranslate}px, 0) scale(${scale})`; // eslint-disable-line no-param-reassign, max-len
    $email.style['-webkit-transform'] = `translate3d(0, ${calcTranslate}px, 0) scale(${scale})`; // eslint-disable-line no-param-reassign, max-len
  });
};

module.exports = {
  init(scrollManager) {
    scrollManager.registerEffect(updateEffect);
  },
};
