const STEP_CHANGE = 0.005;
const UPPER_STEP_BOUNDARY = 0.85;
const LOWER_STEP_BOUNDARY = 0.55;
const PULSE_HEIGHT_MAP = [2, 1, 0, 1, 2];

class SentienceAnimation {

  constructor() {
    this.canvas = document.querySelector('.Sentience');
  }

  init(scrollManager) {
    const images = [
      '/static/img/yellow.png',
      '/static/img/green.png',
      '/static/img/purple.png',
      '/static/img/pink.png',
      '/static/img/blue.png',
    ];

    this.pulses = images.map((src, i) => {
      const image = new Image();
      const loadPromise = new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });
      image.src = src;

      return {
        image,
        loadPromise,
        incrementing: true,
        progress: 0.7 + ((i + 1) * 0.05),
      };
    });

    scrollManager.registerEffect(() => {
      const previousIgnoreRender = this.ignoreRender;
      this.ignoreRender = this.canvas.getBoundingClientRect().top + this.canvas.height < 0;

      if (!this.ignoreRender && previousIgnoreRender) {
        this.requestFrame();
      }
    });

    const imagesLoaded = Promise.all(this.pulses.map(({ loadPromise }) => loadPromise));
    imagesLoaded.then(() => this.requestFrame());
  }

  requestFrame() {
    window.requestAnimationFrame(this.render.bind(this));
  }

  render() {
    if (this.ignoreRender) {
      return;
    }

    const canvas = this.canvas;
    const ctx = canvas.getContext('2d');

    // keep canvas size in sync + clear
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    const pulseCount = this.pulses.length;
    const widthPerPulse = canvas.width / pulseCount;

    this.pulses.forEach((pulse, i) => {
      // compute scale based on width (by breakpoints)
      let scale;
      if (canvasWidth >= 1800) {
        scale = 1.6;
      } else if (canvasWidth < 1800 && canvasWidth >= 1000) {
        scale = 1;
      } else if (canvasWidth < 1000 && canvasWidth >= 700) {
        scale = 0.8;
      } else if (canvasWidth < 700 && canvasWidth >= 400) {
        scale = 0.45;
      } else {
        scale = 0.35;
      }

      // compute the width and height of this pulse
      const imageWidth = pulse.image.naturalWidth * scale;
      const imageHeight = pulse.image.naturalHeight * scale;

      // compute and persist pulse opacity changes (direection + step)
      if (pulse.incrementing && pulse.progress > UPPER_STEP_BOUNDARY ||
        !pulse.incrementing && pulse.progress < LOWER_STEP_BOUNDARY) {
        pulse.incrementing = !pulse.incrementing; // eslint-disable-line no-param-reassign
      }

      const delta = STEP_CHANGE * Math.random();
      pulse.progress = pulse.incrementing ? pulse.progress + delta : pulse.progress - delta; // eslint-disable-line no-param-reassign, max-len

      // compute x / y positions
      // for x, compute the division of the cavas width + account for the origin of the pulse
      const xPosition = (i * widthPerPulse) - (imageWidth / 3);

      // for y, subtract from the canvas height based on the height map +
      // account for the height of the pulse
      const yPosition = canvasHeight + (imageHeight / 16 * PULSE_HEIGHT_MAP[i]) - (imageHeight / 2);

      // render the pulse
      ctx.save();
      ctx.globalAlpha = pulse.progress;
      ctx.drawImage(pulse.image, xPosition, yPosition, imageWidth, imageHeight);
      ctx.restore();
    });

    this.requestFrame();
  }

}

module.exports = SentienceAnimation;
