const $scrolledNavButton = document.querySelector('.Nav--scrolled-button.u-fadeIn');

const landingHeight = document.querySelector('.AboveTheFold').offsetHeight;

const effectsSpec = [
  {
    start: landingHeight - 100,
    end: 5450,
    active() {
      $scrolledNavButton.classList.add('is-visible');
    },

    inactive() {
      $scrolledNavButton.classList.remove('is-visible');
    },
  },
];

const updateEffect = (updateScrollY) => {
  const lastScrollY = updateScrollY;

  effectsSpec.forEach((effect) => {

    if (lastScrollY >= effect.start &&
      (!effect.end || lastScrollY < effect.end)) {
      if (effect.active) {
        effect.active();
      }
    } else {
      if (effect.inactive) {
        effect.inactive();
      }
    }
  });

};

module.exports = {
  init(scrollManager) {
    scrollManager.registerEffect(updateEffect);
  },
};
