const vogue = require('vogue');
const SignupForm = require('./form.react');

const bootstrap = () => {
  vogue.shim({
    SignupForm: SignupForm,
  });
};

module.exports = {
  bootstrap: bootstrap,
};
