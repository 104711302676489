require('../shared/application').setup();

const $ = require('jquery');
require('slick-carousel');

const HELPERS              = require('./helpers');
const form                 = require('../shared/form');
const timeouts             = require('../shared/request-timeout');
const ScrollEffectsManager = require('../shared/scrollEffects');
const scrolledNav          = require('./scrolled-nav');
const emailAnimation       = require('./email-animation');
const SentienceAnimation   = require('./sentience-animation');
const timers               = require('timers');
const moment               = require('moment');

// close alert by clicking on it
$('.flash').click(function() {
  $(this).addClass('u-hidden');
});

// on invite cards, date is always next Wednesday
// in day(), 3 is Wed, +7 means add one week for next Wed
const nextMon = moment().day(1 + 7);
const nextMonFormatted = nextMon.format('dddd, MMMM Do');
const nextWed = moment().day(3 + 7);
const nextWedFormatted = nextWed.format('dddd, MMMM Do');
const nextWedShort = nextWed.format('ddd M/D');
const nextWedArr = nextWed.format('DD MMM ddd').split(' ');
const currentDay = moment().format('dddd');

$('.Email-smartDate-1').text(nextMonFormatted);
$('.Email-smartDate-2').text(nextWedFormatted);
$('.Email-smartDate-3').text(nextWedShort);
$('.Email-smartDate-4').text(currentDay);

$('.InviteBox-date').text(nextWedArr[0]);
$('.InviteBox-month').text(nextWedArr[1]);
$('.InviteBox-day').text(nextWedArr[2]);

//
// Set up testimonials
//

$('.testimonialsSlider').slick({
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  speed: 400,
  pauseOnHover: false,
  pauseOnDotsHover: false,
});

// scrolled nav waitlist button
$('.Nav--scrolled .Button').click(() => {
  $('html, body').animate({
    scrollTop: $('.SignupForm').offset().top,
  }, 500);
});

//
// Learn more button
//

$('.CallToAction .Button--learn').click(() => {
  $('html, body').animate({
    scrollTop: $('.CcClara').offset().top,
  }, 500);
});

//
// Start free trial
//

$('.CallToAction').on('click', '.Button--start', () => {
  // fade out things
  document.querySelector('.CallToAction-hero.is-deactivated').classList.remove('u-isVisible');

  setTimeout(() => {
    // remove them from the DOM
    document.querySelector('.CallToAction-hero.is-deactivated').classList.add('u-isRemoved');

    document.querySelectorAll('.CallToAction-hero.is-activated').forEach((el) => {
      // add form things to DOM
      el.classList.remove('u-isRemoved');

      // fade in things
      setTimeout(() => {
        el.classList.add('u-isVisible');
      }, 40);
    });

  }, 250);

});

$('.CallToAction').on('click', '.Button--cancel', () => {
  // fade out things
  document.querySelectorAll('.CallToAction-hero.is-activated').forEach((el) => {

    const transitionIn = () => {

      // remove them from the DOM
      document.querySelectorAll('.CallToAction-hero.is-activated').forEach((heroEl) => {
        heroEl.classList.add('u-isRemoved');
      });

      // add form things to DOM
      const $originalHero = document.querySelector('.CallToAction-hero.is-deactivated');
      $originalHero.classList.remove('u-isRemoved');

      // fade in things
      timers.setImmediate(() => {
        $originalHero.classList.add('u-isVisible');
      });

      el.removeEventListener('transitionend', transitionIn);
    };

    el.addEventListener('transitionend', transitionIn);

    el.classList.remove('u-isVisible');
  });
});

$(function() {
  timeouts.requestTimeout(() => {
    $('.Sentience').addClass('is-visible');
  }, 100);
});

//
// Fade in elements as we scroll
//

const triggerable = [
  'CcClara',
  'WhatYouGet',
  'Testimonials',
  'SignupForm',
  'ComplexNeeds',
];

// remove initial section u-fadeIn animations on mobile
if (window.Modernizr.touch) {
  triggerable.forEach((item) => {
    document.querySelector(`.${item}`).classList.remove('u-fadeIn');
  });
}

const triggerPoints = triggerable.map((item) => {
  let start;

  if (item === 'SignupForm' && window.innerHeight > 1300) {
    // for very tall monitors, the scroll fadein at the bottom
    // of the page gets screwed up because the page scroll will
    // never reach the calculated trigger point

    const body = document.body;
    const html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    start = height - window.innerHeight;
  } else {
    start = document.querySelector(`.${item}`).offsetTop;
  }

  // start all animations immediately for mobile
  if (window.Modernizr.touch) {
    start = 0;
  }

  return {
    start: start,
    active: HELPERS.playAnimation.bind(HELPERS, item),
  };
});

const scrollManager = new ScrollEffectsManager();

scrollManager.registerEffect((pageYOffset) => {

  const trigger = pageYOffset + (2 * window.innerHeight / 3 + 50);

  triggerPoints.forEach((effect) => {
    if (trigger > effect.start && !effect._activated) {
      effect.active();
      effect._activated = true; // eslint-disable-line no-param-reassign
    }
  });
});

// const sentienceAnimation = new SentienceAnimation();
// sentienceAnimation.init(scrollManager);

emailAnimation.init(scrollManager);
scrolledNav.init(scrollManager);
scrollManager.init();

form.bootstrap();
