class ScrollEffectsManager {

  constructor() {
    this.effects = [];

    this.ticking = false;
    this.lastScrollY = null;
  }

  init() {
    window.addEventListener('scroll', this.requestUpdate.bind(this));
    window.addEventListener('resize', this.requestUpdate.bind(this));

    this.requestUpdate.apply(this);
  }

  registerEffect(fn) {
    this.effects.push(fn);
  }

  update() {
    this.effects.forEach((fn) => {
      fn(this.lastScrollY);
    });

    this.ticking = false;
  }

  requestUpdate() {
    this.lastScrollY = window.pageYOffset;

    if (!this.ticking) {
      window.requestAnimationFrame(this.update.bind(this));
      this.ticking = true;
    }
  }
}

module.exports = ScrollEffectsManager;
